import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppSpinnerComponent } from './core/components/app-spinner/app-spinner.component';
import { AppSessionInitialiser } from './core/services/session/app.session.initialiser';
import { OptixComponentBase } from './core/utils/base-components/optix-component-base';

@Component({
  selector: '',
  templateUrl: './app-initialiser.component.html'
})
export class AppInitialiserComponent extends OptixComponentBase implements OnInit {
  
  constructor(private router: Router, private spinner: AppSpinnerComponent, private appInitialiser: AppSessionInitialiser) {
    super();
    this.logDebug(this.constructor.name, 'loaded');
  }

  public ngOnInit(): void {
    this.logDebug(this.ngOnInit.name, 'initialised');
    this.spinner.show();
    this.appInitialiser.init(this.router.routerState.root.snapshot)
        .subscribe({
          next: (res) => {

          },
          error: (e) => {
            if (e.status === 403) {
              // Request was not allowed.
              console.error('AppInitialiser-Forbidden request.', e);
              Swal.fire('Forbidden', "You are not permitted to perform this action.  Please contact your administrator if you think you should have access.", 'error');
            } else {
              // Request was not authorised.
              console.error('AppInitialiser-Internal API Server Error Occurred.', e);
              Swal.fire('Error', "We're sorry an unexpected error occurred and your request could not be processed.  The error has been recorded and the team are on it.", 'error');
            }
            this.spinner.hide();
          },
          complete: () => {
            this.spinner.hide();
          }
        });
  }
}
