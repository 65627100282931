import { BusinessObjectStatus } from "src/app/core/models/constants/business-object-status-enum";
import { TenantSettingModel } from "../../tenant/configuration/tenant-setting-model";

export class TenantModel {
    public logoFile!: File;
    public tenantSettingModels: TenantSettingModel[] = [];
    public homeZoomLevel: number | undefined;
    public longitude: number | undefined;
    public latitude: number | undefined;

    constructor(public id: string, public name: string, public tenancyName: string, public logoFileType: string, public logo: string, 
        public status: BusinessObjectStatus, public createdDate: Date, public lastModifiedDate: Date) {}
}