export const environment = {
	production: true,
	mode: 'prod',
	optixApi: {
		baseUrl: 'https://api.emissions.ai/',
		applicationId: '00000000-0000-0000-0000-000000000001',
		applicationSecret: 'eaf599a6f17645d7a6d5e45ce2342fa9b0867bbb202c4953aec95a38a04e980f830f116538ca48c2b488a3e4fffe2f94'
	},
	raygunKey: 'ra9ktxXrvPBhpJ7Xu9dfQ',
	b2c: {
		clientId: 'eb1330d5-c16e-4f24-adc4-b6ec6777eb36',
		userFlow: 'b2c_1_si',
		signInAuthority: 'https://emissionsai.b2clogin.com/emissionsai.onmicrosoft.com/b2c_1_si',
		authorityDomain: 'emissionsai.b2clogin.com'
	},
	optixSubDomains: ['', 'app'],
	analytics: {
		baseUrl: 'https://opex-prod-analytics-api.azurewebsites.net/',
		applicationId: '00000000-0000-0000-0000-000000000003',
		apiKey: 'b2da3fc8846a4f3a89208c73a8a4a064-d9f4a67314af47ef8f9420c9ca7a43a3'
	}
};
