import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { AppSpinnerComponent } from "../../components/app-spinner/app-spinner.component";
import { BusinessObjectStatus } from "../../models/constants/business-object-status-enum";
import { SessionState } from "../../models/session/session-state.model";
import { AppSessionService } from "../../services/session/app.session.service";
import { OptixComponentBase } from "./optix-component-base";
import { RolePagePermissions } from '../../models/constants/permissions/role-page-permissions-enum';
import { InvestigationStatus } from "../../models/constants/communications/investigation-status-enum";
import { InsightStatus } from "../../models/constants/communications/insight-status-enum";
import { PageFilterModel } from "../../models/views/page-filter-model";
import { NgxSpinnerService, Size } from "ngx-spinner";
import { EnergyUnit } from "../../models/constants/views/energy-unit.enum";
import { NavigationEnd, Router } from "@angular/router";

declare var require: any;

@Component({
    template: ''
})
export class AppComponentBase extends OptixComponentBase implements OnInit, OnDestroy {
    
    version = require('../../../../../package.json').version;

    public get BusinessObjectStatus(): typeof BusinessObjectStatus { return BusinessObjectStatus; }
    public get RolePagePermissions(): typeof RolePagePermissions { return RolePagePermissions; }
    public get InvestigationStatus(): typeof InvestigationStatus { return InvestigationStatus; }
    public get InsightStatus(): typeof InsightStatus { return InsightStatus; }
    public get EnergyUnit(): typeof EnergyUnit { return EnergyUnit; }
    
    public spinner: AppSpinnerComponent;
    public confirmationService: ConfirmationService;
    public messageService: MessageService;
    public appSessionService: AppSessionService;
    public loadingSpinner: NgxSpinnerService;

    public sessionInfo!: SessionState;
    public isImpersonatedSession: boolean = false;
    
    public sessionStateSubscription: Subscription = new Subscription();
    public pageSubscriptions: Subscription = new Subscription();

    public timezone: string = "Europe/London";
    public pageFilter: PageFilterModel = new PageFilterModel();

    private baseRouter: Router;

    /**
     * This is page name as it appears in the database
     */
    public pageName!: string;

    /**
     * Default constructor
     */
    constructor() { 
        super();

        this.spinner = inject(AppSpinnerComponent);
        this.confirmationService = inject(ConfirmationService);
        this.messageService = inject(MessageService);
        this.appSessionService = inject(AppSessionService);
        this.loadingSpinner = inject(NgxSpinnerService);

        this.baseRouter = inject(Router);
    }

    public ngOnInit(): void {
        this.logDebug(this.ngOnInit.name, 'Base OnInit');
        // Subscribe to the session object
        let sessionSubscription = this.appSessionService.sessionStateObservable().subscribe(s => {
            this.sessionInfo = s;
            if (this.sessionInfo.currentAsset?.timezoneModel.name)
				this.timezone = this.sessionInfo.currentAsset?.timezoneModel.name;
            // If there is no current asset, load the page data as we are most likely on a dashboard selection or admin page that doesnt use the page header filters
            // if (!this.sessionInfo.currentAsset && (
            //     (this.isImpersonatedSession && this.sessionInfo.originalUserSessionState !== undefined) ||
            //     (!this.isImpersonatedSession && this.sessionInfo.originalUserSessionState === undefined) ||
            //     (!this.isImpersonatedSession && this.sessionInfo.originalUserSessionState !== undefined)
            //    ))
            //     this.loadPageData();
            // Set if the session is impersonated
            this.isImpersonatedSession = this.sessionInfo.originalUserSessionState !== undefined;
        });
        this.sessionStateSubscription.add(sessionSubscription);

        // Auto scroll the user back to the top of the page after navigation
        this.baseRouter.events.subscribe((event) => { 
            if (!(event instanceof NavigationEnd)) { 
                return; 
            } 
            window.scrollTo(0, 0) 
        });
    }

    public ngOnDestroy(): void {
        this.logDebug(this.ngOnDestroy.name, 'Base OnDestroy');
        // Unsubscribe from all subscriptions
        this.sessionStateSubscription.unsubscribe();
        this.pageSubscriptions.unsubscribe();
    }

    /**
     * Loads the page data, to be overridden on the page/component to load the specific page data
     */
    public loadPageData(): void {}

    /**
     * Displays the toast message
     * @param type The type of toast ('success', 'error', 'warning')
     * @param title The title of the toast
     * @param message The message of the toast
     */
    public showToast(type: string, title: string, message: string): void {
        this.logDebug(this.showToast.name, 'Displaying message', [type, title, message]);
        // Display the success message
        this.messageService.add({severity: type, summary: title, detail: message});
        // Hide the spinner if it is being displayed
        this.spinner.hide();
    }

    /**
     * Checks the requested permission of this page for the user
     * @param permission The permission to check if the current user has on this page
     */
    public hasPermission(permission: RolePagePermissions): boolean {
        // Create the full permission name and check against the user's roles
        let fullPermissionName = `${this.pageName}_${permission}`;
        return this.appSessionService.getSessionState().roles.includes(fullPermissionName);
    }
}