import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { LoginModel } from "src/app/core/models/authentication/login.model";
import { TokenModel } from "src/app/core/models/authentication/token.model";
import { environment } from "src/environments/environment";
import { ServiceBase } from "../../service-base";
import { SessionIdentityService } from "../identity/session-identity.service";

@Injectable({
    providedIn: 'root'
})
export class SessionTokenService extends ServiceBase {

    private localStorageApiTokenKey: string = "OptixApiToken";
    
    public tokenDetails: TokenModel | undefined;

    constructor(injector: Injector, private identityService: SessionIdentityService) {
        super(injector);
    }

    /**
     * Attempts to get the auth token from local storage
     */
    public getAuthTokenFromLocalStorage(): TokenModel {
        this.logDebug(this.getAuthTokenFromLocalStorage.name, "called");
        // Attempt to get the token details from local storage
        var localStorageItem = localStorage.getItem(this.localStorageApiTokenKey);
        // If the local storage item is not set, get a new token
        if (!localStorageItem) {
            this.logDebug(this.getAuthTokenFromLocalStorage.name, "no token in local storage");
            //return this.generateAuthTokenFromApi(tenancyName, assetId);
            this.tokenDetails = new TokenModel('', new Date(), '');
        }
        else {
            this.tokenDetails = JSON.parse(localStorageItem) as TokenModel;
        }
        this.logDebug(this.getAuthTokenFromLocalStorage.name, 'token retrieved', [this.tokenDetails]);
        // Return the token details
        return this.tokenDetails;
    }

    /**
     * Generates a new auth token from the server
     */
    public generateAuthTokenFromApi(identityId: string, tenancyName: string, assetId?: string): Observable<TokenModel> {
        this.logDebug(this.generateAuthTokenFromApi.name, "called", [tenancyName, assetId]);
        // Set the details to be sent to the server for the token
        var authModel = new LoginModel(environment.optixApi.applicationId, environment.optixApi.applicationSecret, identityId, tenancyName, assetId);
        // Make the request to the server
        return this.httpClient.post<TokenModel>(`${this.baseUrl}v1/authentication`, authModel)
                .pipe(
                    tap(data => {
                        this.tokenDetails = new TokenModel(
                            data.token,
                            new Date(data.expiryDate),
                            data.refreshToken
                        );
                        localStorage.setItem(this.localStorageApiTokenKey, JSON.stringify(this.tokenDetails));
                        this.logDebug(this.generateAuthTokenFromApi.name, 'token retrieved', [this.tokenDetails]);
                    })
                );
    }

    /**
     * Gets a refreshed jwt
     * @param jwt The current jwt for the user.
     * @param refreshToken The refresh token used to validate the current user
     */
    public refreshAuthToken(jwt: string, refreshToken: string) : Observable<TokenModel> {
        this.logDebug(this.refreshAuthToken.name, 'refreshing token...');
        // Create the model for generating the token
        let refreshModel = {
            token: jwt,
            refreshToken: refreshToken
        }
        // Get a new refreshed jwt
        return this.httpClient.post<TokenModel>(`${this.baseUrl}v1/authentication/refresh`, refreshModel)
                .pipe(
                    tap(data => {
                        this.tokenDetails = new TokenModel(
							data.token,
							new Date(data.expiryDate),
							data.refreshToken
						);
                        this.logDebug(this.refreshAuthToken.name, 'token retrieved', [this.tokenDetails]);
                        localStorage.setItem(this.localStorageApiTokenKey, JSON.stringify(this.tokenDetails));
                    })
                );
    }
}