import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CookieComponent } from './pages/cookie/cookie.component';
import { FatalExceptionComponent } from './pages/fatal-exception/fatal-exception.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TermsComponent } from './pages/terms/terms.component';
import { UnauthorisedComponent } from './pages/unauthorised/unauthorised.component';

@NgModule({
  declarations: [
      CookieComponent,
      FatalExceptionComponent,
      PageNotFoundComponent,
      TermsComponent,
      UnauthorisedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MessageModule,
    MessagesModule
  ],
  providers: [],
  exports: [
    MessageModule,
    MessagesModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HomeModule {}
