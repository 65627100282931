import { Injectable, Injector } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ServiceBase } from "../../service-base";

@Injectable({
    providedIn: 'root'
})
export class SessionIdentityService extends ServiceBase {

    constructor(injector: Injector, private msAuthService: MsalService) {
        super(injector);
    }
    
    /**
     * Gets if the user is authenticated
     * @returns true if the user is authenticated
     */
    public isAuthenticated(): boolean {
        return this.msAuthService.instance.getAllAccounts().length > 0
    }

    /**
     * Gets the id of the user from the identity provider
     * @returns the identity id for the logged in user
     */
    public getIdentityId(): string {
        return this.msAuthService.instance.getAllAccounts()[0].localAccountId;
    }

    /**
     * Logs the user out.
     * @param postLogoutUri The url to redirect the user to after logout
     */
    public logout(postLogoutUri: string): void {
        this.msAuthService.logout({postLogoutRedirectUri: postLogoutUri});
    }
}