import { FlareStackModel } from "../asset/emissions/flaring/flare-stack-model";
import { UserTypes } from "../constants/permissions/user-types-enum";
import { TenantModel } from "../global/clients/tenant-model";
import { AssetModel } from "../tenant/location/asset-model";

export class SessionState {
    /**
     * Is the user authenticated
     */
    public isAuthenticated: boolean = false;
    /**
     * Has the session been initialised
     */
    public isSessionInialised: boolean = false;
    /**
     * The tenants that the user has access to.
     */
    public tenants: TenantModel[];
    /**
     * The tenant that the user is currently accessing.
     */
    public currentTenant?: TenantModel;
    /**
     * The assets that the user has access to in the current tenant
     */
    public assets: AssetModel[];
    /**
     * The asset that the user is currently accessing
     */
    public currentAsset?: AssetModel;
    /**
     * The identity id of the logged in user
     */
    public identityId!: string;
    /**
     * The id of the logged in user
     */
    public userId?: string;
    /**
     * The email address of the logged in user
     */
    public email?: string;
    /**
     * The firstname of the logged in user
     */
    public firstname?: string;
    /**
     * The lastname of the logged in user
     */
    public lastname?: string;
    /**
     * Has the user accepted the privacy and terms etc
     */
    public hasAcceptedPrivacy: boolean = false;
    /**
     * The type of user
     */
    public userType: UserTypes = UserTypes.None;
    /**
     * The roles of the logged in user
     */
    public roles: string[];
    /**
     * The authorization JWT
     */
    public jsonWebToken: string;
    /**
     * The expiry for the token
     */
    public tokenExpiry: Date;
    /**
     * The refresh token
     */
    public refreshToken: string;
    /**
     * The currently selected flare stack
     */
    public currentFlareStack?: FlareStackModel;

    /**
     * The url that the admin impersonating the user came from
     */
    public impersonationAdminUrl?: string;

    /**
     * The original session state of the admin impersonating the user
     */
    public originalUserSessionState?: SessionState;
    
    constructor() {
        this.tenants = [];
        this.assets = [];
        this.roles = [];
        this.jsonWebToken = '';
        this.tokenExpiry = new Date();
        this.refreshToken = '';
    }
}