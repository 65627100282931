import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AvatarModule } from 'primeng/avatar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { BrandComponent } from './brand/brand.component';
import { EnvironmentBannerComponent } from './environment-banner/environment-banner.component';
import { MenuComponent } from './app-menu/menu.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  declarations: [
      AppSpinnerComponent,
      BrandComponent,
      AppFooterComponent,
      AppHeaderComponent,
      EnvironmentBannerComponent,
      MenuComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    RouterModule,
    FormsModule,
    AvatarModule,
    MessageModule,
    MessagesModule,
    CardModule,
    SidebarModule,
    ButtonModule,
    PanelMenuModule,
    DropdownModule,
    DialogModule,
    SplitButtonModule
],
  providers: [
    ConfirmationService,
    MessageService
  ],
  exports: [
    AppSpinnerComponent,
    AppFooterComponent,
    AppHeaderComponent,
    MenuComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoreComponentsModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreComponentsModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
}
