import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { UserModel } from "src/app/core/models/global/clients/user-model";
import { AssetModel } from "src/app/core/models/tenant/location/asset-model";
import { AssetUserModel } from "src/app/core/models/tenant/permissions/asset-user-model";
import { UserAssetModel } from "src/app/core/models/tenant/permissions/user-asset-model";
import { ServiceBase } from "../../../service-base";

@Injectable({
    providedIn: 'root'
})
export class AssetUserService extends ServiceBase {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the tenants for the current user.
     * @returns Observable with the tenants for the user.
     */
    public getUsersAssets(): Observable<AssetModel[]> {
        return this.httpQueue.get<AssetModel[]>(`${this.baseUrl}v1/assetuser/getassetsforuser`);
    }

    /**
     * Gets the users for the asset
     * @param assetId The id of the asset to get the users for
     */
    public getAssetUsersForAsset(assetId: string): Observable<AssetUserModel[]> {
        return this.httpQueue.get<AssetUserModel[]>(`${this.baseUrl}v1/assetuser/getassetusersforasset/${assetId}`);
    }

    /**
     * Gets the users for the asset
     * @param assetId The id of the asset to get the users for
     */
    public getUsersForAsset(assetId: string): Observable<UserModel[]> {
        return this.httpQueue.get<UserModel[]>(`${this.baseUrl}v1/AssetUser/GetUsersForAsset/${assetId}`);
    }

    /**
     * Gets the users assets with permission.
     * @param userId The id of the user to the the assets and permissions for.
     * @returns Collection of UserAssetModels
     */
    public GetAssetsForTenantWithUserPermissions(userId: string): Observable<UserAssetModel[]> {
        return this.httpQueue.get<UserAssetModel[]>(
			`${this.baseUrl}v1/AssetUser/GetAssetsForTenantWithUserPermissions/${userId}`
		);
    }

    /**
     * Gets the users assets with permission.
     * @param userId The id of the user to the the assets and permissions for.
     * @returns Collection of UserAssetModels
     */
    public GetAssetsForTenantWithUserPermissionsByTenantId(tenantId:string, userId: string): Observable<UserAssetModel[]> {
        return this.httpQueue.get<UserAssetModel[]>(
			`${this.baseUrl}v1/AssetUser/GetAssetsForTenantWithUserPermissionsByTenantId/${tenantId}/${userId}`
		);
    }

	/**
	 * Deletes the user.
	 * @param user The user to be deleted.
	 * @returns Observable with the deleted user details.
	 */
	public deleteUserByAssetId(assetId: string, user: UserModel): Observable<any> {
		return this.httpQueue.delete<any>(
			`${this.baseUrl}v1/AssetUser/DeleteUserByAssetId/${assetId}/${user.id}`
		);
	}

	/**
	 * Restore the user.
	 * @param user The user to be restore.
	 * @returns Observable with the restored user details.
	 */
	public restoreUserByAssetId(assetId: string, user: UserModel): Observable<any> {
		return this.httpQueue.put<any>(
			`${this.baseUrl}v1/AssetUser/RestoreUserByAssetId/${assetId}/${user.id}`, ''
		);
	}
}