import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TenantModel } from '../../models/global/clients/tenant-model';
import { AssetModel } from '../../models/tenant/location/asset-model';
import { MenuService } from '../../services/session/menu/menu.service';
import { OptixComponentBase } from '../../utils/base-components/optix-component-base';

@Component({
  templateUrl: './menu.component.html',
  selector: 'menu-bar'
})
export class MenuComponent extends OptixComponentBase implements OnChanges {
  
    @Input() menuVisible: boolean;
    @Output() menuVisibleChange: EventEmitter<boolean> = new EventEmitter();

    @Input() tenant?: TenantModel;
    @Input() asset?: AssetModel;
  
    public menuItems!: MenuItem[];
  
    constructor(private menuService: MenuService) {
        super();
        this.menuVisible = false;
    }
  
    ngOnChanges(changes: SimpleChanges): void {
        this.logDebug(this.ngOnChanges.name, 'Entering...', [changes]);
        // If the tenant or asset has changed, refresh the menu
        if (changes['tenant'] || changes['asset']) {
            this.logInfo(this.ngOnChanges.name, 'Refreshing menu after change to tenant or asset', [changes]);
            this.menuItems = this.menuService.getMenuItems(() => { this.onHideSideBar(); });
        }
        this.logDebug(this.ngOnChanges.name, 'Leaving...');
    }
  
    public onHideSideBar(): void {
        this.logDebug(this.onHideSideBar.name, 'Entering...');
        this.menuVisible = false;
        this.menuVisibleChange.emit(this.menuVisible);
        this.logDebug(this.onHideSideBar.name, 'Leaving....');
    }
}
