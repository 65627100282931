import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppInitialiserComponent } from './app-initialiser.component';
import { AppSpinnerComponent } from './core/components/app-spinner/app-spinner.component';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { RoleGuard } from './core/utils/guards/role-guard';
import { CookieComponent } from './modules/home/pages/cookie/cookie.component';
import { FatalExceptionComponent } from './modules/home/pages/fatal-exception/fatal-exception.component';
import { PageNotFoundComponent } from './modules/home/pages/page-not-found/page-not-found.component';
import { TermsComponent } from './modules/home/pages/terms/terms.component';
import { UnauthorisedComponent } from './modules/home/pages/unauthorised/unauthorised.component';

const routes: Routes = [
    { path: '', component: AppInitialiserComponent, canActivate: [MsalGuard] },
    { path: 'unauthorised', component: UnauthorisedComponent, title: 'Unauthorised' },
    { path: 'fatalexception', component: FatalExceptionComponent, title: 'Exception', canActivate: [MsalGuard] },
    { path: 'pagenotfound', component: PageNotFoundComponent, title: 'Page Not Found' },
    { path: 'account/cookie', component: CookieComponent, title: 'Cookies' },
    { path: 'account/terms', component: TermsComponent, title: 'Terms' },
    { path: 'main', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule), canActivate: [MsalGuard], canActivateChild: [MsalGuard, RoleGuard] },
    { path: 'root', loadChildren: () => import('./modules/root/root.module').then(m => m.RootModule), canActivate: [MsalGuard], canActivateChild: [MsalGuard, RoleGuard] },
    { path: '**', component: PageNotFoundComponent, canActivate: [MsalGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router, private spinner: AppSpinnerComponent, private analyticsService: AnalyticsService) {
        this.router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.spinner.show();
            }
            
            if (event instanceof RouteConfigLoadEnd) {
                this.spinner.hide();
            }
            
            if (event instanceof NavigationEnd) {
                this.analyticsService.recordUserAnalytics(true);
            }
        });
    }
}
