import { FlareStackModel } from "../asset/emissions/flaring/flare-stack-model";
import { PollutantTypeModel } from "../asset/emissions/pems/pollutant-type-model";
import { UnitModel } from "../asset/inventory/unit-model";
import { ChartScale } from "../constants/views/chart-scale-enum";
import { EnergyUnit } from "../constants/views/energy-unit.enum";
import { TenantModel } from "../global/clients/tenant-model";
import { AssetCategorySetModel } from "../tenant/permissions/asset-category-set.model";

export class PageFilterModel {
    public chartName!: string;
    public pageName!: string;
    public pageArea!: string;

    public energyUnit!: EnergyUnit;
    public scale!: ChartScale;

    public initialDate!: Date;
    public endDate!: Date;

    public tag!: UnitModel;
    public pollutantType!: PollutantTypeModel;

    public fuelType!: string;

    public flareStack!: FlareStackModel;
    public flareCategorySet!: AssetCategorySetModel;
    public flareCategorySetLevel: number = 1;

    public plainTenant!: string;
    public tenant!: TenantModel;

    public username!: string;

    public powerType!: string;

    public initialDateLocal!: Date;
    public endDateLocal!: Date;

    public ch4Co2eToggle: string = 'ch4';

    public curveType!: string;

    public unitType!: string;
    public system!: string;

    public monthNumber: number = 0;

    public injectionLogId!: string;

    constructor() { }
}
